import React, { createContext, useState } from "react";

export const UserContext = createContext({
    user: {},
    loginUser: (userProps) => {},    
});
const UserContextProvider = ({ children }) => {
    const [user, setUser] = useState({
        userName: "",
        userId: 0,
        userAlias: "",
        userRol: 0,
        userJWT: "",
        userSuperUsuario: false,
    });
    const loginUser = (userProps) => {
        setUser({ ...userProps });
    };
    return (
        <UserContext.Provider
            value={{
                loginUser,
                user
            }}>
            {children}
        </UserContext.Provider>
    );
};

export default UserContextProvider;
