import React from "react";
import "./cargando.css";

const Cargando = ({modal = false}) => {
    return (
        <div className={`fullWidth grillaCentro ${modal ? 'cargando-modal' : null}`}>
            <div className='loader'></div>
        </div>
    );
};

export default Cargando;
