import React, { lazy, Suspense, useState } from "react";
import "./App.css";
import "./atomic.css";

// import DatosHistoricos from "components/datos-historicos/"
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Pagination } from "swiper/core";
import "swiper/swiper.min.css";
import "swiper/components/navigation/navigation.min.css";
import "dashboard.css";
import "swiper.css";
import "dashboard-bo.css";
import "colors.css";
import Cargando from "components/cargando/cargando.component";


const Equipo = lazy(() =>
	import("components/Equipo/equipo.component")
);
const EquipoEstatus = lazy(() =>
	import("components/Equipo/equipo-estatus.component")
);

const DiasSinContacto = lazy(() =>
	import("components/dias-sin-contacto/dias-sin-contacto.component")
);
const Flujo = lazy(() =>
	import("components/flujo/flujo.component")
);

const CierresTotales = lazy(() =>
	import("components/cierres-totales/cierres-totales.component")
);
const DatosHistoricos = lazy(() =>
	import("components/datos-historicos/datos-historicos.component")
);
const ProyeccionAltas = lazy(() =>
	import("components/proyeccion-altas/proyeccion-altas.component")
);
const DatosMensuales = lazy(() =>
	import("components/datos-mensuales/datos-mensuales.component")
);
const CasosDemorados = lazy(() =>
	import("components/casos-demorados/casos-demorados.component")
);
const Mood = lazy(() =>
	import("components/mood/mood.component")
);
SwiperCore.use([Navigation, Pagination]);
function App() {
	const [activeIndex, setActiveIndex] = useState(0);
	const pagination = {
		clickable: true,
		renderBullet: function (index, className) {
			if (dashPages[index].noDisplay) return [];
			return `<span class="${className}">${
				dashPages[index].titulo || index + 1
			}</span>`;
		},
	};
	const dashPages = [
		{
			titulo: "Clientes Totales por Status",
			componente: <CierresTotales />,
		},
		{
			titulo: "Datos del Mes",
			componente: <DatosMensuales />,
		},
		{
			titulo: "Proyección: Conversión Altas",
			componente: <ProyeccionAltas />,
		},
		{
			titulo: "Casos Demorados",
			componente: <CasosDemorados />,
		},
		{
			titulo: "Datos Historicos",
			componente: <DatosHistoricos />,
		},
		{
			titulo: "30 días sin contacto",
			componente: <DiasSinContacto />,
		},
		{
			titulo: "Flujo",
			componente: <Flujo />,
		},
		{
			titulo: "Binomios",
			componente: <Equipo />,
		},
		{
			titulo: "Totales Batman",
			componente: <EquipoEstatus equipo="Batman"  />,
		},
		{
			titulo: "Totales Harry Potter",
			componente: <EquipoEstatus equipo="Harry Potter"  />,
		},
		{
			titulo: "Totales Terminator",
			componente: <EquipoEstatus equipo="Terminator"  />,
		},
		{
			titulo: "Totales Los Vengadores",
			componente: <EquipoEstatus equipo="Los Vengadores"  />,
		},
		{
			titulo: "Mood Cliente",
			componente: <Mood />,
		},
	];
	return (
		<div className="App">
			<Swiper
				pagination={pagination}
				navigation={true}
				className="mySwiper"
				onSlideChange={(swiper) => {
					setActiveIndex(swiper.activeIndex);
				}}
			>
				{dashPages.map((p, ix) => (
					<SwiperSlide key={p.titulo}>
						{activeIndex === ix ? (
							<Suspense fallback={<Cargando />}>
								<div className="chartContainer">
									<div className="chartTitle bigValue">{p.titulo}</div>
									{p.componente}
								</div>
							</Suspense>
						) : null}
					</SwiperSlide>
				))}
			</Swiper>
		</div>
	);
}

export default App;
